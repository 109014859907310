import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Log in to `}<a parentName="p" {...{
        "href": "https://trailguide.net/portal"
      }}>{`https://trailguide.net/portal`}</a>{` and find activity that you want to
change.`}</p>
    <br />
If you want to make your destination the official owner of the trail, you must
first add it to your pool of activities by clicking the checkbox and then
you can click the <b>+ Add [your destination name]</b>.
    <Image src="pro/add-destination-stamp.jpg" mdxType="Image" />
    <p>{`All users will now see your destination as the trail owner (you only see it
when you are logged out)`}</p>
    <Image src="pro/official-trail-owner.jpg" mdxType="Image" />
    <br />
    <br />
    <Link className="float-left" to="/pro/upload" mdxType="Link">
  ← Pictures and videos
    </Link>
    <Link className="float-right" to="/pro/map" mdxType="Link">
  Create a custom map →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      